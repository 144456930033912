import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";

export const query = graphql`
  query {
    szansy: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kontrahenci: file(relativePath: { eq: "lista-kontrahentow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kartakontrahenta: file(
      relativePath: { eq: "kartakontrahenta-atrybuty.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kartainfo: file(
      relativePath: { eq: "kartakontrahenta-podstawoweinformacje.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdarzenia: file(relativePath: { eq: "kartakontrahenta-zdarzenia.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const BazaKontrahentow = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Historia współpracy z kontrahentami"
      headline="Proste segmentowanie kontrahentów"
    >
      <SEO
        title="Historia współpracy z kontrahentami"
        description="Historia współpracy z kontrahentami ✅ Proste segmentowanie kontrahentów • Szybkie wyszukiwanie • Własne cechy opisujące kontrahentów • Zapytaj o bezpłatne demo!"
        keywords={["baza kontrahentów"]}
      />
      <p>
        W <strong>branży reklamowej</strong> nie można przecenić wartości
        dobrego kontaktu z kontrahentem. Wiele{" "}
        <strong>zleceń i projektów</strong> wymaga poczynienia{" "}
        <strong>szeregu ustaleń</strong>, a komunikacja często jest dodatkowo
        skomplikowana obecnością <strong>plików, zdjęć i dokumentów</strong>.
        Empireo ułatwi Twojej <strong>agencji reklamowej</strong> gromadzenie
        informacji o wykonanych do tej pory i zaplanowanych wobec kontrahenta
        działaniach. Możesz powiązać z nim{" "}
        <strong>spotkania, korespondencję mailową</strong> i wszelkie inne{" "}
        <strong>zdefiniowane przez Ciebie zdarzenia</strong>. Pozwoli to uzyskać{" "}
        <strong>kompletną historię kontaktów</strong> - prawdziwą skarbnicę
        wiedzy dla Ciebie i Twoich pracowników.
      </p>

      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[
          data.kartainfo,
          data.kartakontrahenta,
          data.zdarzenia,
          data.kontrahenci,
        ]}
        alts={[
          "Karta kontrahenta w systemie dla marketingu, Karta kontrahenta z własnymi atrybutami opisującymi kontrahenta w systemie dla agencji reklamowej, lista kontrahentów w agencji reklamowej, pełna historia współpracy z kontrahentem w systemie dla marketingu",
        ]}
      />
      <h3>Kluczowe możliwości:</h3>
      <p>
        <ul>
          <li>
            <strong>Zadania i zdarzenia</strong> – umożliwiają tworzenie
            kompleksowej historii kontaktów z kontrahentem, w której znajdą się
            opisy poczynionych ustaleń, przesłane oferty i tym podobne
            informacje.
          </li>
          <li>
            <strong>Tworzenie własnych pól na kartotece klienta</strong> - czyli
            sposób na dowolną segmentację Twoich klientów i wyszukiwanie ich po
            statusie współpracy, branży, skali czy dowolnej innej, ważnej dla
            Twojej agencji zmiennej.
          </li>
          <li>
            <strong>Ewidencja wiadomości e-mail</strong> – w kartotekach
            kontrahentów automatycznie archiwizują się powiązane z nimi
            wiadomości e-mail.
          </li>
        </ul>
      </p>
      <p
        style={{
          marginTop: "30px",
        }}
      >
        {" "}
        Zebrane w historii kontaktów dane są łatwo{" "}
        <strong>dostępne dla wszystkich zaangażowanych</strong> w projekt
        pracowników, dzięki czemu będą mogli{" "}
        <strong>lepiej i szybciej obsłużyć Twoich klientów</strong>. Ty zaś
        zyskujesz narzędzie, które będzie gromadzić cenną wiedzę na temat Twoich
        klientów. Pozwoli to Twojej agencji reklamowej{" "}
        <strong>tworzyć lepiej dopasowane projekty i oferty</strong>.
      </p>
    </FunctionPageLayout>
  );
};

export default BazaKontrahentow;
